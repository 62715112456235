import request from '@/utils/request'
/**
 * 获取担保公司列表
 */
export const orginlist = (e) => {
  // console.log("获取担保公司列表",e);
  return request({
    method: 'get',
    url: '/guarantee/orgin/list',
    // /QBaoHan/comtypelist
    params: e
  })
}
/**
 获取保函详情
 */
export const applyprogress = (mobile) => {
  return request({
    method: 'get',
    url: `/QBaoHan/applyprogress`,
    headers: {
      // "Content-Type":"multipart/form-data;"
      // "Content-Type":"application/x-www-form-urlencoded"
    },
    params: {
      applyno: mobile
    }
  })
}
// 保函申请提交
export const companyst = (add) => {
  // console.log(add);
  return request({
    method: 'get',
    url: '/QBaoHan/apply_add',
    params: add
  })
}
// 保函申请状态
export const bhprocess = (dat) => {
  return request({
    method: 'get',
    url: '/QBaoHan/bhprocess',
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    },
    params: {
      applyno: dat
    }
  })
}
// 查询公司信息是否存在
export const ukeylist = (dat) => {
  return request({
    method: 'get',
    url: '/system/ukey/list',
    params: {
      unifyCode: dat
    }
  })
}

// 判断企业授信
export const orgcredit = (dat) => {
  return request({
    method: 'get',
    url: '/QBaoHan/org_credit',
    params: dat
  })
}

// filesign(data) {//签章
export const filesign = (cion) => {
  // var bbc=cion
  return request({
    method: 'get',
    url: '/QBaoHan/filesign',
    params: {
      ...cion
      // apply_number:"132"
    }
  })
}

// 密文保函状态
export const bhfileState = (bodys) => {
  return request({
    method: 'get',
    url: '/QBaoHan/fileState',
    params: {
      applyno: bodys
    }
  })
}
// 协议签章
export const yulansign = (e) => {
  // console.log("获取担保公司列表",e);
  return request({
    method: 'get',
    url: '/agreemant/yulansign',
    // /QBaoHan/comtypelist
    params: e
  })
}
// 签章页面申请详情
export const getbhfile = (bodys) => {
  return request({
    method: 'get',
    url: '/agreemant/getbhfile',
    params: {
      applyno: bodys
    }
  })
}
//获取详情
export const bhinfo = (b) => {
  return request({
    method: 'get',
    params: {
      baohanno: b
    },
    url: '/QBaoHan/bhinfo'
  })
}

// 下载文件
export const snfiledown = (dat) => {
  return request({
    method: 'get',
    url: '/QBaoHan/snfiledown',
    params: dat
  })
}

//保函申请通知
export const baohannotice = (file) => {
  return request({
    method: 'get',
    url: '/mobile/xdbh/baohannotice',
    // headers: { "Content-Type": "multipart/form-data" },

    params: {
      applyno: file
    }
  })
}

//协议
export const yulan = (file) => {
  return request({
    method: 'get',
    url: '/agreemant/yulan',
    // headers: { "Content-Type": "multipart/form-data" },

    params: {
      applyno: file
    }
  })
}
//
// /**
//  * 用户相关请求模块
//  */

// // import store from '@/store'

// export const weixincode = (e) => {
//   return request({
//     method: 'POST',
//     url: '/api/Common/openid',
//     data:{
//       weixin_code:e
//     }
//   })
// }
// /**
//  * 获取公司列表
//  */

// export const getUserChannels = () => {

//   return request({
//     method: "POST",
//     url: "/tripartite/mobile/xbhan/comtypelist",
//   });
// };
// //获取详情
// export const bhinfo = (b) => {

//   return request({
//     method: "POST",
//     data: {
//       baohanno:b,
//     },
//     url: "/tripartite/mobile/xbhan/bhinfo",
//   });
// };
// export const companylist = () => {
//   var b = sessionStorage.getItem("wxcodes");
// // b = b.split("|");
// // var uid = b[0];
// // var token = b[1];
//   return request({
//     method: "POST",
//     url: "/api/index/companylist",
//     data: {
//       user_uuid:b,
//     },
//   });
// };

// // 获取银行信息
// export const bhtypelist = (b) => {
//   // console.log(b);
//   return request({
//     method: "POST",
//     url: "/tripartite/mobile/xbhan/bankinfo",
//     data: {
//       unify_code:b.cin,
//       applyno:b.cion,
//     },
//   });
// };
// //提交支付凭证
// export const entryUserInfo = (b) => {
//   return request({
//     method: "POST",
//     url: "/tripartite/mobile/xbhan/zfbank",
//     data: {
//      ...b
//     },
//   });
// };
// // createcontract(data) {//创建合同
// export const createcontract = (cion) => {
// // var bbc=cion
//   return request({
//     method: "POST",
//     url: "/tripartite/mobile/xbhan/createcontract",
//     data: {
//      ...cion
//       // apply_number:"132"
//     },
//   });
// };
// // filesign(data) {//签章
// export const filesign = (cion) => {
//   // var bbc=cion
//   return request({
//     method: "POST",
//     url: "/tripartite/mobile/xbhan/filesign",
//     data: {
//      ...cion,
//       // apply_number:"132"
//     },
//   });
// };
// // bhfiledown(data) {//合同下载
// export const bhfiledown = (cion) => {
//   // var bbc=cion
//   return request({
//     method: "POST",
//     url: "/tripartite/mobile/xbhan/bhfiledown",
//     data: {
//       ...cion,
//       // apply_number:"132"
//     },
//   });
// };
// //获取保函文件
// export const companybhlist = (cion) => {
//   return request({
//     method: "POST",
//     url: "/tripartite/mobile/xbhan/getbhfile",
//     data: {
//       baohanno:cion,
//       // apply_number:"132"
//     },
//   });
// };
// // 保函还原通知
// export const restorenotice = (bodys) => {

//   return request({
//     method: "POST",
//     url: "/tripartite/mobile/Xdbh/restorenotice_post",
//     data: {
//       applyno:bodys,
//     },
//   });
// };
// //退保申请
// export const loginadd = (dat) => {
//   return request({
//     method: "POST",
//     url: "/tripartite/mobile/Xdbh/baohannotice",
//     data: {
//       baohanno:"",
//       applyno:dat,
//     },
//   });
// };
// //保函申请进度
// export const userlogin = (data) => {
//   // console.log(data);
//   return request({
//     method: "POST",
//     url: "/tripartite/mobile/xbhan/applyprogress",
//     data: {
//       applyno:data,
//     }
//   });
// };

// /**
//  注册
//  */
// export const applyprogress = (mobile) => {
//   return request({
//     method: "post",
//     url: `/tripartite/mobile/xbhan/applyprogress`,
//     data: {
//       applyno: mobile
//     },
//   });
// };

// /**
//  http://bill.fasttest.com:8081/api/index/ApplyForInvoice
//  申请发票
//  */
// export const getUserInfo = (bodys) => {

//   return request({
//     method: "POST",
//     url: "/tripartite/mobile/Xdbh/invoiceapply",
//     data: {
//       ...bodys,
//     },
//   });
// };
// // 申请发票通知
// export const invoicenoticepost = (bodys) => {

//     return request({
//       method: "POST",
//       url: "/tripartite/mobile/Xdbh/invoicenotice_post",
//       data: {
//         applyno:bodys,
//       },
//     });
//   };
//   // 密文保函状态
// export const bhfileState = (bodys) => {

//   return request({
//     method: "POST",
//     url: "/tripartite/mobile/xbhan/fileState",
//     data: {
//       applyno:bodys,
//     },
//   });
// };

// // 下载文件
// export const snfiledown= (dat) => {
//   return request({
//     method: "POST",
//     url: "tripartite/mobile/xbhan/snfiledown",
//     data: {
//      ...dat
//     },
//   });
// };

// // 判断企业授信
// export const orgcredit= (dat) => {
//   return request({
//     method: "POST",
//     url: "tripartite/mobile/xbhan/org_credit",
//     data: {
//      ...dat
//     },
//   });
// };
