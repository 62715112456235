/**
 * 封装 axios 请求模块
 */
//  import router from '../router/router';
// var userAgent = navigator.userAgent   // 获取浏览器信息
// var IEReg = new RegExp('MSIE \\d+\\.\\d+;');   // 正则校验IE标识符
// var IEMsg = IEReg.exec(userAgent)   // 获取字符串
// var IEVersionNum = new RegExp('\\d+\\.\\d');   // 正则获取版本
// var urldi=""
// // 是IE9，引入js文件
// if(IEMsg && parseFloat(IEVersionNum.exec(IEMsg)) == 9){
//   urldi="https://www.hnzyxsbh.com/"
// }else{
//   urldi="http://www.hnzyxsbh.com/"
// }
import axios from 'axios'
//http://192.168.0.198:8081/api/index/bhlist
const request = axios.create({
  // baseURL: 'https://www.hnzyxsbh.com/' // 基础路径
  // baseURL: 'http://192.168.0.76:18080' // 基础路径 之前的
  baseURL: 'https://www.hnzyxsbh.com/tripartite/' // 基础路径 之前的
  // baseURL: urldi // 基础路径
  // baseURL: 'http://192.168.1.172:18080' // 基础路径
})

// const that=this
// //3. 定义前置拦截器，请求拦截器，请求发送出去之前触发的
request.interceptors.request.use(
  (config) => {
    // console.log("config",config);

    //config 接口请求的配置信息
    // config.headers.Authorization='Bearer ' + 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjE3NGMxOTdmLTI0OGQtNDg4Yy1hZGFjLTAwOGUyZjEwZDg1MSJ9.CzVnVMl2IxyJXK_hkqUhlHz8jAaEtK2uY03sK5X3cD7jgSxi7SOPerh3BZjKUNnVlGQC9d1t-X1q3lGvpNMfVw'
    // console.log("config: " , config);
    return config
  },
  (error) => {
    //报错的是时候抛出一个报错的信息
    return Promise.reject(error.response.status)
  }
)

// //4. 定义后置拦截器,响应拦截器, 服务器响应回来数据之前触发，
// request.interceptors.response.use((response) => {
// // console.log("response:",response.status);

//   //响应回来的数据操作
//   return response;
// }, (error) => {

//   // console.log(error.response.status);

//   //报错的是时候抛出一个报错的信息
//   return Promise.reject(error);
// })
request.interceptors.response.use((response) => {
  // console.log("请求成功",response)
  // IE 8-9
  if (
    response.data == null &&
    response.config.responseType === 'json' &&
    response.request.responseText != null
  ) {
    try {
      // eslint-disable-next-line no-param-reassign
      response.data = JSON.parse(response.request.responseText)
      console.log('成功', response.data)
    } catch (e) {
      console.log('错误', e)
      // ignored
    }
  }
  // console.log("抛出错误",response)
  return response
})

export default request
